import React from 'react';
import { FaLinkedin } from "react-icons/fa";


const DeputyHead = ({ dhead }) => {
    return (
        
        <div className="p-2 transition ease-in-out hover:scale-105 duration-200  jusitify-content: center ">
      <div className="glassIco min-h-[300px] pt-8 flex flex-col justify-start items-center relative">
        <div className='img'>
          <img
            src={`/team_2024-25/${dhead.name}.jpg`}
            alt="dhead" />
        </div>
        <div className="mt-2 text-center">
          <p className="my-1 tracking-[1px]">{dhead.name}</p>
          <p className="my-1 tracking-[1px]">{`${dhead.year} year, ${dhead.department} dept`}</p>
          <a href={dhead.lnurl} target="_blank" rel="noreferrer" className="mx-2">
            <FaLinkedin className="inline" size="1.1em" />
          </a>
        </div>
        <div className="absolute bottom-0 left-0 right-0 domain text-center py-3 ">
          <p className="font-bold">{dhead.domain}</p>
        </div>
      </div>
    </div>
    )
}

export default DeputyHead;